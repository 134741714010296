.type-item {
  padding: 15px;
  text-align: center;
  border: 1px solid #d9d9d9;
  background-color: #f0f2f5;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s;
}

.type-item:hover {
  background-color: #e6f7ff;
  transform: scale(1.03);
}

.type-item.selected {
  background-color: #bae7ff;
  font-weight: bold;
}

.type-item + .type-item {
  margin-left: 10px;
}
